import request from '@/utils/request'

// 查询徽章系列名称列表
export function listBadgeSeries(query) {
  return request({
    url: '/badge/badge/series/list',
    method: 'get',
    params: query
  })
}

// 查询徽章系列名称详细
export function getBadgeSeries(id) {
  return request({
    url: '/badge/badge/series/' + id,
    method: 'get'
  })
}

// 新增徽章系列名称
export function addBadgeSeries(data) {
  return request({
    url: '/badge/badge/series',
    method: 'post',
    data: data
  })
}

// 修改徽章系列名称
export function updateBadgeSeries(data) {
  return request({
    url: '/badge/badge/series',
    method: 'put',
    data: data
  })
}

// 删除徽章系列名称
export function delBadgeSeries(id) {
  return request({
    url: '/badge/badge/series/' + id,
    method: 'delete'
  })
}
