<template>
  <a-modal :visible="visible" title="发行列表" width="50%" v-highlight @cancel="handleCancel" :footer="null">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="12">
            <a-col :md="8" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <template v-slot:logo="text, record">
          <img v-if="record.logo" :src="record.logo" style="width:60px;height:62px;" @preview="handlePreview" />
        </template>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="sellers" slot-scope="text, record" :title="record.sellers.map(item => item.name).join(', ')">
          {{ record.sellers.map(item => item.name).join(', ') }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleBind(record)">
            绑定
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import { listScriptConfig } from '@/api/script/scriptConfig'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ScriptReleaseModal',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      visible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 预览封面
      previewVisible: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: 3,
        name: undefined,
        logo: undefined,
        wechat: undefined,
        isAuth: undefined,
        publishNum: undefined,
        saleNum: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '配置类型',
          dataIndex: 'typeName',
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'logo图片',
          dataIndex: 'logo',
          scopedSlots: { customRender: 'logo' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '微信号',
        //   dataIndex: 'wechat',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否认证',
        //   dataIndex: 'isAuthName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '发行数',
        //   dataIndex: 'publishNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '销量',
        //   dataIndex: 'saleNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    handleCancel () {
      this.visible = false
    },
    /** 查询剧本列表 */
    getList() {
      this.loading = true
      listScriptConfig(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handlePreview(file) {
      console.log('打开了')
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        type: 3,
        name: undefined,
        logo: undefined,
        wechat: undefined,
        isAuth: undefined,
        publishNum: undefined,
        saleNum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 绑定按钮操作 */
    handleBind(row) {
      const ids = row.id || this.ids
      console.log('获取到的', ids)
      this.visible = false
      this.$emit('select', ids)
      this.$emit('selectAll', row)
    },
    open() {
      this.visible = true
    }
  }
}
</script>
<style scoped>
>>> .table-page-search-wrapper .ant-form-inline .ant-form-item, >>> .table-page-search-submitButtons {
  margin-bottom: 0;
}
>>> .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>