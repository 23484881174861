<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="名片名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名片名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="名片副标题" prop="subtitle">
                <a-input v-model="queryParam.subtitle" placeholder="请输入名片副标题" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['card:card:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['card:card:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['card:card:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['card:card:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="type" slot-scope="text, record">
          <custom-dict-tag :options="customDict.CardTypeEnum" :value="record.type"/>
        </span>
        <span slot="level" slot-scope="text, record">
          <custom-dict-tag :options="customDict.CardLevelEnum" :value="record.level"/>
        </span>
        <div slot="cardImage" slot-scope="text, record" style="cursor: pointer;">
          <img v-if="record.cardImage" :src="record.cardImage" style="width:100px;" preview alt=""/>
        </div>
        <div slot="shareCardImage" slot-scope="text, record" style="cursor: pointer;">
          <img v-if="record.shareCardImage" :src="record.shareCardImage" style="width:100px;" preview alt=""/>
        </div>
        <span slot="status" slot-scope="text, record">
          <custom-dict-tag :options="customDict.CardStatusEnum" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.userGrantList.open(record.id)" v-if="record.level === 2" v-hasPermi="['coupon:coupon:edit']">
            <a-icon type="user" />发放用户
          </a>
          <a-divider type="vertical" v-if="record.level === 2" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['card:card:edit']">
            <a-icon type="edit" />修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['card:card:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['card:card:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <user-grant-list ref="userGrantList" @grantUser="grantUser"></user-grant-list>
  </page-header-wrapper>
</template>

<script>
import { listCard, delCard } from '@/api/card/card'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from '@/components/DictCustomTag/index.vue'
import { mapGetters } from 'vuex'
import UserGrantList from '@/components/User/UserGrantList.vue'
import { addCardUser } from '@/api/card/cardUser'

export default {
  name: 'Card',
  components: {
    UserGrantList,
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        subtitle: null,
        type: null,
        level: null,
        shareCardImage: null,
        cardImage: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '名片名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名片副标题',
          dataIndex: 'subtitle',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '名片类型（暂未用）',
        //   dataIndex: 'type',
        //   scopedSlots: { customRender: 'type' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '名片等级',
          dataIndex: 'level',
          scopedSlots: { customRender: 'level' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分享名片图片',
          dataIndex: 'shareCardImage',
          scopedSlots: { customRender: 'shareCardImage' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名片图片',
          dataIndex: 'cardImage',
          scopedSlots: { customRender: 'cardImage' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 240,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    console.log('进入名片列表页面！')
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询名片夹列表 */
    getList () {
      this.loading = true
      listCard(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        subtitle: undefined,
        type: undefined,
        level: undefined,
        shareCardImage: undefined,
        cardImage: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delCard(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('card/card/export', {
            ...that.queryParam
          }, `card_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    grantUser (row, relationId) {
      const that = this
      this.$confirm({
        title: '确认给`' + row.nickName + '`发放名片?',
        onOk() {
          return addCardUser({ userId: row.id, cardId: relationId })
            .then(() => {
              that.$refs.userGrantList.onSelectChange([], [])
              that.$refs.userGrantList.getList(3)
              that.$message.success(
                '发放成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
