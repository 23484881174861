import request from '@/utils/request'

// 查询店铺剧本信息列表
export function listShopScript(query) {
  return request({
    url: '/shop/shop/script/list',
    method: 'get',
    params: query
  })
}

// 查询店铺剧本信息详细
export function getShopScript(id) {
  return request({
    url: '/shop/shop/script/' + id,
    method: 'get'
  })
}

// 新增店铺剧本信息
export function addShopScript(data) {
  return request({
    url: '/shop/shop/script',
    method: 'post',
    data: data
  })
}

// 修改店铺剧本信息
export function updateShopScript(data) {
  return request({
    url: '/shop/shop/script',
    method: 'put',
    data: data
  })
}

// 删除店铺剧本信息
export function delShopScript(id) {
  return request({
    url: '/shop/shop/script/' + id,
    method: 'delete'
  })
}

// 编辑店铺剧本价格
export function editScriptPrice(data) {
  return request({
    url: '/shop/shop/script/edit/price',
    method: 'post',
    data
  })
}
