import request from '@/utils/request'

// 查询剧本角色排行榜列表
export function listScriptRoleRank(query) {
  return request({
    url: '/script/role/rank/list',
    method: 'get',
    params: query
  })
}

// 查询剧本角色排行榜详细
export function getScriptRoleRank(id) {
  return request({
    url: '/script/role/rank/' + id,
    method: 'get'
  })
}

// 新增剧本角色排行榜
export function addScriptRoleRank(data) {
  return request({
    url: '/script/role/rank',
    method: 'post',
    data: data
  })
}

// 修改剧本角色排行榜
export function updateScriptRoleRank(data) {
  return request({
    url: '/script/role/rank',
    method: 'put',
    data: data
  })
}

// 删除剧本角色排行榜
export function delScriptRoleRank(id) {
  return request({
    url: '/script/role/rank/' + id,
    method: 'delete'
  })
}

// 批量更新剧本角色排行版数据
export function batchUpdateScriptRoleRank(data) {
  return request({
    url: '/script/role/rank/batch/update',
    method: 'put',
    data: data
  })
}

