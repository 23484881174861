<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model-item label="推荐类型" prop="type">
        <a-select style="width: 250px" v-model="form.type" placeholder="请输入推荐类型">
          <a-select-option
            v-for="(item, index) in this.customDict.ScriptRecommendTypeEnum"
            :value="item.type"
            :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="剧本编号" prop="scriptId">
          <a-input v-model="form.scriptId" placeholder="请输入剧本id" disabled="" />
          <a @click="handleInfo(form.id)">绑定剧本</a>
        </a-form-model-item>
        <a-form-model-item label="推荐理由" prop="reason">
          <a-input v-model="form.reason" placeholder="请输入内容" type="textarea" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sort" v-if="form.type!==3">
          <a-input v-model="form.sort" placeholder="请输入排序" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="是否首页展示" prop="isHome" v-if="form.type!==3">
          <a-switch v-model="form.isHome" />{{ form.isHome ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="开始时间" prop="startTime">
          <a-date-picker style="width: 100%" @change="onDateChange" format="YYYY-MM-DD" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="结束时间" prop="endTime">
          <!--        <a-date-picker style="width: 100%" v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
          <a-date-picker style="width: 100%" @change="onDateChange1" format="YYYY-MM-DD" allow-clear />
        </a-form-model-item>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-script-modal ref="IndexScriptModal" @select="indexScriptModalSelect"></index-script-modal>
  </div>
</template>

<script>
import { getScriptRecommend, addScriptRecommend, updateScriptRecommend } from '@/api/script/scriptRecommend'
import IndexScriptModal from '@/components/Script/IndexScriptModal'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    IndexScriptModal
  },
  data() {
    // const startTimeVal = (rule, value, callback) => {
    //   if (this.form.type !== 3 && !value) {
    //       callback(new Error('开始时间不能为空'))
    //   }
    //   callback()
    // }
    // const endTimeVal = (rule, value, callback) => {
    //   if (this.form.type !== 3 && !value) {
    //       callback(new Error('开始时间不能为空'))
    //   }
    //   callback()
    // }
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        scriptId: null,
        startTime: null,
        endTime: null,
        reason: null,
        type: null,
        sort: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null,
        isHome: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        scriptId: [
          { required: true, message: '剧本id不能为空', trigger: 'blur' }
        ],
        reason: [
          { required: true, message: '推荐理由不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '排序不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '结束时间不能为空', trigger: 'blur' }
        ],
        isHome: [
          { required: true, message: '是否首页展示不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: { ...mapGetters(['customDict']) },
  watch: {},
  mounted() {
  },
  methods: {
    indexScriptModalSelect(ids) {
      this.form.scriptId = ids
      console.log('获取到的ids', ids)
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        scriptId: null,
        reason: null,
        type: null,
        startTime: null,
        endTime: null,
        sort: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null,
        isHome: null
      }
    },
    handleInfo: function(id) {
      this.$refs.IndexScriptModal.open()
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.form.startTime = dateString
    },
    onDateChange1(date, dateString) {
      console.log(date, dateString)
      this.form.endTime = dateString
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getScriptRecommend(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      if (this.form.type === 3) {
        this.form.sort = 1
        this.form.isHome = false
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateScriptRecommend(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addScriptRecommend(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
