<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户名称" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用户名称" allow-clear/>
            </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="票根名称" prop="ticketName">
                <a-input v-model="queryParam.ticketName" placeholder="请输入票根名称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="填写店铺名称" prop="fillShopName">
                  <a-input v-model="queryParam.fillShopName" placeholder="请输入填写店铺名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="票根唯一编码" prop="ticketSerialNumber">
                  <a-input v-model="queryParam.ticketSerialNumber" placeholder="请输入票根唯一编码" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="剩余交易次数" prop="remainingTransNumber">
                  <a-input v-model="queryParam.remainingTransNumber" placeholder="请输入剩余交易次数" allow-clear/>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="票根转赠时间" prop="transferTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.transferTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col> -->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['ticket:ticketStubUser:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['ticket:ticketStubUser:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <!--
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['ticket:ticketStubUser:remove']">
          <a-icon type="delete" />删除
        </a-button>
        -->
        <a-button type="primary" @click="handleExport" v-hasPermi="['ticket:ticketStubUser:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        :data-source="list"
        :rowKey="(record, index) => index + 1"
        :columns="columns"
        :customRow="columnClick"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <template v-slot:transferTime="text, record">
          {{ parseTime(record.transferTime) }}
        </template>
        <template v-slot:createTime="text, record">
          {{ parseTime(record.createTime) }}
        </template>
        <template v-slot:status="text, record">
          <custom-dict-tag :options="customDict.TicketStubUserStatusEnum" :value="record.status"/>
        </template>
        <template v-slot:operation="text, record">
          <a-divider type="vertical" v-hasPermi="['ticket:ticketStubUser:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['ticket:ticketStubUser:edit']">
            <a-icon type="edit" />修改
          </a>
          <!--
          <a-divider type="vertical" v-hasPermi="['ticket:ticketStubUser:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['ticket:ticketStubUser:remove']">
            <a-icon type="delete" />删除
          </a>
          -->
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listTicketStubUser, delTicketStubUser } from '@/api/ticket/ticketStubUser'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from '@/components/DictCustomTag/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TicketStubUser',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        ticketId: null,
        fillShopName: null,
        fillDate: null,
        fillTime: null,
        fillRole: null,
        ticketSerialNumber: null,
        diyImage: null,
        remainingTransNumber: null,
        transferTime: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 60,
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '用户id 外键user_user.id',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户名称',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '票根id',
        //   dataIndex: 'ticketId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '票根名称',
          dataIndex: 'ticketName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '填写店铺名称',
        //   dataIndex: 'fillShopName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '填写日期',
        //   dataIndex: 'fillDate',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '填写时间',
        //   dataIndex: 'fillTime',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '填写角色',
        //   dataIndex: 'fillRole',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '票根唯一编码',
          dataIndex: 'ticketSerialNumber',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '自定义图片',
        //   dataIndex: 'diyImage',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '剩余交易次数',
          dataIndex: 'remainingTransNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '票根转赠时间',
          dataIndex: 'transferTime',
          scopedSlots: { customRender: 'transferTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '使用状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询票根用户列表 */
    getList () {
      this.loading = true
      listTicketStubUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        ticketId: undefined,
        fillShopName: undefined,
        fillDate: undefined,
        fillTime: undefined,
        fillRole: undefined,
        ticketSerialNumber: undefined,
        diyImage: undefined,
        remainingTransNumber: undefined,
        transferTime: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    verifyStatus() {
      this.single = this.selectedRowKeys.length !== 1
      this.multiple = !this.selectedRowKeys.length
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.verifyStatus()
    },
    columnClick(row, index) {
      return {
        on: {
          click: () => {
            if (this.selectedRowKeys.includes(index + 1)) {
              const keyIndex = this.selectedRowKeys.findIndex(i => i === index + 1)
              this.selectedRowKeys.splice(keyIndex, 1)
              this.selectedRows.splice(keyIndex, 1)
              this.ids.splice(keyIndex, 1)
              this.verifyStatus()
            } else {
              this.selectedRowKeys.push(index + 1)
              this.selectedRows.push(row)
              this.ids.push(row.id)
              this.verifyStatus()
            }
          }
        }
      }
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delTicketStubUser(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('ticket/ticketStubUser/export', {
            ...that.queryParam
          }, `ticketStubUser_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
