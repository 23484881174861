import request from '@/utils/request'

// 查询运营号聊天数纪录分页
export function getGmUserIMUnreadList(query) {
  return request({
    url: '/gm/user/im/unread/counts/list',
    method: 'get',
    params: query
  })
}

// 查询运营号互动消息分页
export function queryInteractiveMsgList(query) {
  return request({
    url: '/gm/user/interactive/msg/list',
    method: 'get',
    params: query
  })
}

// 运营号关注某人
export function gmUserFocusUser(data) {
  return request({
    url: '/gm/user/focus',
    method: 'get',
    params: data
  })
}

// 指定运营号互动消息标记已读
export function markReadGmUserMsg(ids) {
  return request({
    url: '/gm/user/mark/read/msg/' + ids,
    method: 'post'
  })
}