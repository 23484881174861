<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户" prop="userId">
        <a-button type="dashed" @click="$refs.userCardRef.open()" danger>
          {{ form.userName ? form.userName : '绑定用户' }}
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="关联类型" prop="type" >
        <a-select style="width: 100%" v-model="form.type" @change="changeType" placeholder="请选择关联类型" allow-clear>
          <a-select-option :value=1>签约作者</a-select-option>
          <a-select-option :value=2>商家人员</a-select-option>
          <a-select-option :value=3>签约发行</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="剧本名称" prop="scriptName" v-if="form.type === 1">
        <a-button type="dashed" @click="$refs.indexScriptModal.open()" danger>
          {{ form.scriptName ? form.scriptName : '绑定剧本' }}
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName" v-if="form.type === 2">
        <a-button type="dashed" @click="$refs.shopListModal.open()" danger>
          {{ form.shopName ? form.shopName : '绑定店铺' }}
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="发行名称" prop="releaseName" v-if="form.type === 3">
        <a-button type="dashed" @click="$refs.scriptReleaseModal.open()" danger>
          {{ form.releaseName ? form.releaseName : '绑定发行' }}
        </a-button>
      </a-form-model-item>
<!--      <a-form-model-item label="关系id 根据类型指定剧本id/店铺id/发行id" prop="relationId" >-->
<!--        <a-input v-model="form.relationId" placeholder="请输入关系id 根据类型指定剧本id/店铺id/发行id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">-->
<!--        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">-->
<!--        <a-input v-model="form.version" placeholder="请输入乐观锁" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <user-card-list
      ref="userCardRef"
      :isGmUser=false
      @bindUser="bindUser"
    />
    <index-script-modal
      ref="indexScriptModal"
      @selectAll="bindScript"
    />
    <shop-list-modal
      ref="shopListModal"
      @selectAll="bindShop"
    />
    <script-release-modal
      ref="scriptReleaseModal"
      @selectAll="bindRelease"
    />
  </a-drawer>
</template>

<script>
import { getUserRelation, addUserRelation, updateUserRelation } from '@/api/user/userRelation'
import UserCardList from '@/components/User/UserCardList.vue'
import IndexScriptModal from '@/components/Script/IndexScriptModal.vue'
import ShopListModal from '@/components/Shop/ShopListModal.vue'
import ScriptReleaseModal from '@/components/Script/ScriptReleaseModal.vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    ScriptReleaseModal,
    ShopListModal,
    IndexScriptModal,
    UserCardList
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        userName: null,
        type: undefined,
        relationId: null,
        scriptName: null,
        shopName: null,
        releaseName: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '请先绑定用户', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '关联类型不能为空', trigger: 'change' }
        ],
        // relationId: [
        //   { required: true, message: '关系id 根据类型指定剧本id/店铺id/发行id不能为空', trigger: 'blur' }
        // ],
        scriptName: [
          { required: true, message: '请绑定剧本', trigger: 'blur' }
        ],
        shopName: [
          { required: true, message: '请绑定店铺', trigger: 'blur' }
        ],
        releaseName: [
          { required: true, message: '请绑定发行', trigger: 'blur' }
        ],
        // isDeleted: [
        //   { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        // ],
        // version: [
        //   { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        userName: null,
        type: undefined,
        relationId: null,
        scriptName: null,
        shopName: null,
        releaseName: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserRelation(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUserRelation(this.form).then(() => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUserRelation(this.form).then(() => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    bindUser (row) {
      this.form.userId = row.userId
      this.form.userName = row.nickName
    },
    changeType(val) {
      this.form.relationId = null
      this.form.scriptName = null
      this.form.shopName = null
      this.form.releaseName = null
      this.form.type = val
    },
    bindScript (row) {
      this.form.relationId = row.id
      this.form.scriptName = row.name
    },
    bindShop (row) {
      this.form.relationId = row.id
      this.form.shopName = row.shopName
    },
    bindRelease (row) {
      this.form.relationId = row.id
      this.form.releaseName = row.name
    }
  }
}
</script>
