<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">
              <a-form-item label="店铺id" prop="shopId">
                <a-input v-model="queryParam.shopId" placeholder="请输入店铺id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本id" prop="scriptId">
                <a-input v-model="queryParam.name" placeholder="请输入剧本名称" allow-clear/>
              </a-form-item>
            </a-col>-->

            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入剧本名称" allow-clear/>
              </a-form-item>
            </a-col>

<!--            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="是否收录 1:是,0:否" prop="isInclude">
                  <a-input v-model="queryParam.isInclude" placeholder="请输入是否收录 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否上架 1:是,0:否" prop="isOnSale">
                  <a-input v-model="queryParam.isOnSale" placeholder="请输入是否上架 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否热推 1:是,0:否" prop="isHot">
                  <a-input v-model="queryParam.isHot" placeholder="请输入是否热推 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否上新 1:是,0:否" prop="isNew">
                  <a-input v-model="queryParam.isNew" placeholder="请输入是否上新 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否预热 1:是,0:否" prop="isPre">
                  <a-input v-model="queryParam.isPre" placeholder="请输入是否预热 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否镇店 1:是,0:否" prop="isTreasure">
                  <a-input v-model="queryParam.isTreasure" placeholder="请输入是否镇店 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
            </template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:shop/script:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:shop/script:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:shop/script:remove']">
          <a-icon type="delete" />删除
        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:shop/script:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="changePrice(record)" v-hasPermi="['shop:shop/script:edit']">
            <a-icon type="edit" />修改价格
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['shop:shop/script:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:shop/script:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['shop:shop/script:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['shop:shop/script:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <a-modal :visible="priceModel" title="修改价格" :width="400" v-highlight @ok="submitChange" @cancel="handleCancel">
      <a-form-model-item label="工作日价格" style="margin-bottom: 10px">
        <a-input-number
          v-model="priceData.workdayPrice"
          style="width: 100%"
          :min="20"
          :max="9999"
          :precision="0"
          placeholder="请输入价格"
        />
      </a-form-model-item>
      <a-form-model-item label="休息日/节假日价格" style="margin-bottom: 10px">
        <a-input-number
          v-model="priceData.dayOffPrice"
          style="width: 100%"
          :min="20"
          :max="9999"
          :precision="0"
          placeholder="请输入生成数量"
        />
      </a-form-model-item>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { listShopScript, delShopScript, editScriptPrice } from '@/api/shop/shopScript'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ShopScript',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: null,
        scriptId: null,
        isInclude: null,
        isOnSale: null,
        price: null,
        isHot: null,
        isNew: null,
        isPre: null,
        preInfo: null,
        isTreasure: null,
        shopName: null,
        name: null,
        treasureInfo: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
       /* {
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
       /* {
          title: '店铺id',
          dataIndex: 'shopId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '剧本id',
          dataIndex: 'scriptId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '剧本名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '是否收录 1:是,0:否',
          dataIndex: 'isInclude',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否上架 1:是,0:否',
          dataIndex: 'isOnSale',
          ellipsis: true,
          align: 'center'
        },*/
        // {
        //   title: '价格 元/人',
        //   dataIndex: 'price',
        //   ellipsis: true,
        //   align: 'center'
        // },
        /*{
          title: '是否热推 1:是,0:否',
          dataIndex: 'isHot',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否上新 1:是,0:否',
          dataIndex: 'isNew',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否预热 1:是,0:否',
          dataIndex: 'isPre',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '新本预热信息 json {"totalNum":"预热门票数量","validTime","预计到店时间","minOrderNum":"购买数量","prePrice":"23.00"}',
          dataIndex: 'preInfo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否镇店 1:是,0:否',
          dataIndex: 'isTreasure',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '镇店信息 json {"validTime","到期时间","treasurePrice":"23.00"}',
          dataIndex: 'treasureInfo',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      priceModel: false,
      priceData: {
        shopId: null,
        scriptId: null,
        workdayPrice: 20,
        dayOffPrice: 20
      }
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询店铺剧本信息列表 */
    getList () {
      this.loading = true
      listShopScript(this.queryParam).then(response => {
        this.list = response.data.records
        this.scriptScript=response.data.records.scriptScript
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopId: undefined,
        scriptId: undefined,
        isInclude: undefined,
        isOnSale: undefined,
        price: undefined,
        isHot: undefined,
        isNew: undefined,
        isPre: undefined,
        preInfo: undefined,
        isTreasure: undefined,
        treasureInfo: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delShopScript(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('shop/shop/script/export', {
            ...that.queryParam
          }, `shop/script_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    changePrice (row) {
      this.priceData.changeId = row.shopId
      this.priceData.scriptId = row.scriptId
      this.priceData.workdayPrice = JSON.parse(row.price).workdayPrice
      this.priceData.dayOffPrice = JSON.parse(row.price).dayOffPrice
      this.priceModel = true
    },
    handleCancel () {
      this.priceModel = false
      this.priceData = {
        shopId: null,
        scriptId: null,
        workdayPrice: 20,
        dayOffPrice: 20
      }
    },
    submitChange () {
      const data = {
        shopId: this.priceData.changeId,
        scriptId: this.priceData.scriptId,
        workdayPrice: this.priceData.workdayPrice,
        dayOffPrice: this.priceData.dayOffPrice
      }
      this.loading = true
      editScriptPrice(data).then(res => {
        if (res.success) {
          this.$message.success("修改店铺剧本价格成功")
          this.handleCancel()
          this.getList()
        } else {
          this.$message.error("修改店铺剧本价格失败：" + res.message)
        }
        this.loading = false
      }).catch(err => {
        this.$message.error(err.message)
        this.loading = false
      })
    }
  }
}
</script>
