<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="版本名称, xx.yy.zz" prop="versionName">
        <a-input v-model="form.versionName" placeholder="请输入版本号, xx.yy.zz"/>
      </a-form-model-item>
      <a-form-model-item label="版本号" prop="versionCode">
        <a-input-number :min="0" v-model="form.versionCode" placeholder="请输入版本号" style="width: 100%" />
      </a-form-model-item>
      <a-form-model-item label="升级内容" prop="content">
        <a-textarea v-model="form.content"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="是否强制更新" prop="musted">
<!--        <api-select v-model="form.musted" placeholder="请选择强制更新" :option="[-->
<!--          { id: '0', name: '不强制' },-->
<!--          { id: '1', name: '强制' },-->
<!--        ]" />-->
        <a-select v-model="form.musted"  placeholder="请选择强制更新" allow-clear>
          <a-select-option :value=0>不强制</a-select-option>
          <a-select-option :value=1>强制</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否展示弹窗" prop="isShow">
        <a-select v-model="form.isShow"  placeholder="请选择是否展示弹窗" allow-clear>
          <a-select-option :value=0>否</a-select-option>
          <a-select-option :value=1>是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="平台" prop="platform">
<!--        <api-select v-model="form.platform" placeholder="请选择平台" :option="[-->
<!--          { id: '1', name: '安卓' },-->
<!--          { id: '2', name: 'IOS' },-->
<!--        ]" />-->
        <a-select v-model="form.platform"  placeholder="请选择平台" allow-clear>
          <a-select-option :value=1>安卓</a-select-option>
          <a-select-option :value=2>苹果</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="客户端类型" prop="client">
<!--        <api-select v-model="form.client" placeholder="请选择客户端类型" :option="[-->
<!--          { id: '1', name: '用户端' },-->
<!--          { id: '2', name: '商家端' },-->
<!--        ]" />-->

        <a-select v-model="form.client" placeholder="请选择客户端类型" allow-clear>
          <a-select-option :value=1>用户端</a-select-option>
          <a-select-option :value=2>商家端</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="下载地址类型" prop="url">
        <a-radio-group v-model="urlSwitch" name="radioGroup">
          <a-radio :value="true">上传文件获取地址</a-radio>
          <a-radio :value="false">输入地址</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="下载地址(安卓端)" prop="url" v-show="urlSwitch">
        <oss-multi-upload v-model="form.url" bucket="common" :maxSize="500" :maxCount="1"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="下载地址" prop="url" v-show="!urlSwitch">
        <a-input v-model="form.url" placeholder="请输入下载地址" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-textarea v-model="form.remark" placeholder="请输入内容" :rows="4" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVersion, addVersion, updateVersion } from '@/api/app/version'
import Editor from '@/components/Editor'
import ApiSelect from '@/views/config/badge/modules/ApiSelect.vue';
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor, ApiSelect, OssMultiUpload
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        remark: null,
        versionName: null,
        versionCode: null,
        content: null,
        musted: 0,
        isShow: 1,
        platform: null,
        client: null,
        url: null
      },
      urlSwitch: false,
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        content: [
          { required: true, message: '升级内容不能为空', trigger: 'blur' }
        ],
        versionName: [
          { required: true, message: '版本名称不能为空', trigger: 'blur' }
        ],
        versionCode: [
          { required: true, message: '版本号不能为空', trigger: 'blur' }
        ],
        platform: [
          { required: true, message: '平台不能为空', trigger: 'blur' }
        ],
        client: [
          { required: true, message: '客户端类型不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        remark: null,
        versionCode: null,
        versionName: null,
        content: null,
        musted: 0,
        isShow: 1,
        platform: null,
        client: null,
        url: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVersion(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVersion(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVersion(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
