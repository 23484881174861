<template>
    <a-modal v-model:visible="visible" title="剧本列表" width="60%" v-highlight>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="标签库id,多个之间用逗号分隔" prop="label">
                  <a-input v-model="queryParam.label" placeholder="请输入标签库id,多个之间用逗号分隔" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="operation" slot-scope="text, record">
          <a @click="bindShop(record)">
            <a-icon type="delet" />绑定
          </a>
        </span>
        <template slot="logo" slot-scope="text, record">
          <img v-if="record.logo" :src="record.logo" style="width:60px;height:62px;" @preview="handlePreview"/>
        </template>
        <template slot="isPay" slot-scope="text, record">
          <span>{{record.isPay ? '已缴纳' : '未缴纳'}}</span>
        </template>
        <template slot="isAuth" slot-scope="text, record">
          <span>{{record.isAuth ? '是' : '否'}}</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status === 1" color="blue">申请中</a-tag>
          <a-tag v-if="record.status === 2" color="green">正常</a-tag>
          <a-tag v-if="record.status === 3" color="red">封禁</a-tag>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    </a-modal>
</template>

<script>
import { getShopList, delShop,banShop,unsealShop,getShopCountList } from '@/api/shop/shop'
import { tableMixin } from '@/store/table-mixin'
import { parseTime } from '../../utils/ruoyi'

export default {
  name: 'ShopAudit',
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      shopNum: null,
      shopSumAmount: '0',
      shopSetAmount: '0',
      shopUnSetAmount: '0',
      data:[],
      selectedRowKeys: [],
      selectedRows: [],
      selectedId: '',
      shopStatus: '3',
      reason: '',
      visible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        legalPerson: null,
        mobile: null,
        shopName: null,
        servicePhone: null,
        label: null,
        img: null,
        status: 2,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺logo',
          dataIndex: 'logo',
          scopedSlots: { customRender: 'logo' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          width: 80,
          align: 'center'
        },
        // {
        //   title: '认证',
        //   dataIndex: 'isAuth',
        //   scopedSlots: { customRender: 'isAuth' },
        //   ellipsis: true,
        //   width: 120,
        //   align: 'center'
        // },
        {
          title: '联系电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '保证金',
          dataIndex: 'isPay',
          scopedSlots: { customRender: 'isPay' },
          ellipsis: true,
          width: 85,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          width: 170,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '12%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getShopCountList();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    parseTime,
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 查询店铺审核信息列表 */
    getList () {
      this.loading = true
      getShopList(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 查询店统计信息列表 */
    getShopCountList () {
      this.loading = true
      getShopCountList(this.queryParam).then(response => {
        this.data = response.data
       // this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        legalPerson: undefined,
        mobile: undefined,
        shopName: undefined,
        servicePhone: undefined,
        label: undefined,
        img: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delShop(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {}
      })
    },
    /** 封禁按钮操作 */
    handleBan (row) {
      var that = this
      const ids = row.id || this.ids
      const shopName = row.shopName
      this.$confirm({
        title: '确认封禁该店铺吗?',
        content: '当前选中店铺：' + shopName,
        onOk () {
          return banShop(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '封禁成功',
                3
              )
            })
        },
        onCancel () {}
      })
    },
    /** 解封按钮操作 */
    handleUnseal (row) {
      var that = this
      const ids = row.id || this.ids
      const shopName = row.shopName
      this.$confirm({
        title: '确认解封该店铺吗?',
        content: '当前选中店铺：' + shopName,
        onOk () {
          return unsealShop(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '解封成功',
                3
              )
            })
        },
        onCancel () {}
      })
    },
    bindShop (row) {
        const ids = row.id || this.ids;
        const name = row.shopName
        this.visible = false;
        this.$emit('select', ids)
        this.$emit('selectname', name)
        this.$emit('selectAll', row)
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('shop/shop/audit/export', {
            ...that.queryParam
          }, `shop/audit_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleOk() {
      shopAuditVerify({ id: this.selectedId, status: this.shopStatus, reason: this.reason }).then(response => {
        if (response.success) {
          this.$notification.success({
            message: '审批成功'
          })
        }
      }).finally(() => {
        this.shopStatus = '3'
        this.visible = false
        this.selectedId = ''
        this.reason = ''
        this.handleQuery()
      })
    },
    /** 审批 */
    handleAudit (row, ids) {
      this.formType = 2
      const id = row ? row.id : ids
      this.visible = true
      this.selectedId = id
    },
    open(){
        this.visible = true;
    }
  }
}
</script>
<style lang="less" scoped>

.card {
  border: 1px solid #e8e8e8;
  padding: 10px;
  margin-bottom: 20px;
}

.card > .card-money {
  font-size: 18px;
  color: #ec5551;
}

.card .border-right {
  border-right: 1px dashed #e8e8e8 !important;
}

.card .border-bottom {
  border-bottom: 1px dashed #e8e8e8 !important;
}

/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item,
/deep/ .table-page-search-submitButtons {
  margin-bottom: 0;
}
/deep/ .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>