import request from '@/utils/request'

// 查询徽章用户使用列表
export function listBadgeUser(query) {
  return request({
    url: '/badge/badge/user/list',
    method: 'get',
    params: query
  })
}

// 查询徽章用户使用详细
export function getBadgeUser(id) {
  return request({
    url: '/badge/badge/user/' + id,
    method: 'get'
  })
}

// 新增徽章用户使用
export function addBadgeUser(data) {
  return request({
    url: '/badge/badge/user',
    method: 'post',
    data: data
  })
}

// 修改徽章用户使用
export function updateBadgeUser(data) {
  return request({
    url: '/badge/badge/user',
    method: 'put',
    data: data
  })
}

// 删除徽章用户使用
export function delBadgeUser(id) {
  return request({
    url: '/badge/badge/user/' + id,
    method: 'delete'
  })
}
