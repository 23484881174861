import request from '@/utils/request'

// 查询用户认证关系列表
export function listUserRelation(query) {
  return request({
    url: '/user/user/relation/list',
    method: 'get',
    params: query
  })
}

// 查询用户认证关系详细
export function getUserRelation(id) {
  return request({
    url: '/user/user/relation/' + id,
    method: 'get'
  })
}

// 新增用户认证关系
export function addUserRelation(data) {
  return request({
    url: '/user/user/relation',
    method: 'post',
    data: data
  })
}

// 修改用户认证关系
export function updateUserRelation(data) {
  return request({
    url: '/user/user/relation',
    method: 'put',
    data: data
  })
}

// 删除用户认证关系
export function delUserRelation(id) {
  return request({
    url: '/user/user/relation/' + id,
    method: 'delete'
  })
}
