<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="封面图" prop="cover">
          <oss-multi-upload v-model="form.cover" :maxCount="1" type="img"></oss-multi-upload>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="form.title" placeholder="请输入标题" type="textarea"/>
        </a-form-model-item>
        <a-form-model-item label="简介, 外部显示使用" prop="introduction">
          <a-input v-model="form.introduction" placeholder="请输入简介, 外部显示使用"/>
        </a-form-model-item>
        <a-form-model-item label="跳转类型" prop="jumpType">
          <a-select style="width: 250px" v-model="form.jumpType" placeholder="请输入标签" @change="changeType">
            <a-select-option v-for="(item, index) in this.customDict.JumpTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-form-model-item label="请绑定店铺" prop="jumpInfo" v-if="isShow2">
            <div @click="handleInfoShop(form.id)">
              <a-button type="dashed" @click="handleInfoShop(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定剧本" prop="jumpInfo" v-if="isShow">
            <div @click="handleInfo(form.id)">
              <a-button type="dashed" @click="handleInfo(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定活动" prop="jumpInfo" v-if="isShow4">
            <div>
              <a-button type="dashed" @click="handleInfoActivity(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定展会" prop="jumpInfo" v-if="isShow5">
            <div>
              <a-button type="dashed" @click="handleInfo5(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定剧本集" prop="jumpInfo" v-if="isShow3">
            <div>
              <a-button type="dashed" @click="handleInfo3(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定用户" prop="jumpInfo" v-if="isShow6">
            <div @click="handleInfoUser(form.id)">
              <a-button type="dashed" @click="handleInfoUser(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定商家" prop="jumpInfo" v-if="isShow7">
            <div @click="handleInfoShop(form.id)">
              <a-button type="dashed" @click="handleInfoShop(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请输入外联链接" prop="jumpInfo"v-if="isShow9">
            <a-input v-model="form.jumpInfo" placeholder="请输入外联链接"/>
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <editor v-model="form.content"/>
        </a-form-model-item>

        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-script-modal ref="IndexScriptModal" @select="indexScriptModalSelect"
                        @selectName="indexScriptModalName"></index-script-modal>
    <index-expo-modal ref="IndexExpoModal" @select="indexExpoModalSelect"
                      @selectname="indexExpoModalName"></index-expo-modal>
    <shop-list-modal ref="ShopListModal" @select="shopListModalSelect"
                     @selectname="shopListModalSelectName"></shop-list-modal>
    <sheet-list-modal ref="SheetListModal" @select="sheetListModalSelect"
                      @selectname="sheetListModalSelectName"></sheet-list-modal>
    <activity-list-modal ref="ActivityListModal" @select="activityListModalSelect"
                         @selectname="activityListModalSelectName"></activity-list-modal>
    <user-list-modal ref="UserListModal" @select="userListModalSelect"
                     @selectname="userListModalSelectName"></user-list-modal>
  </div>
</template>


<script>
import {getTuantuan, addTuantuan, updateTuantuan} from '@/api/notice/tuantuan'
import Editor from '@/components/Editor';
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import IndexExpoModal from "@/views/expo/expoScript/modules/indexExpoModal";
import ShopListModal from "@/components/Shop/ShopListModal.vue";
import SheetListModal from "@/views/script/sheet/SheetListModal";
import UserListModal from "@/views/user/user/UserListModal";
import ActivityListModal from "@/views/activity/activity/ActivityListModal";
import IndexScriptModal from "@/components/Script/IndexScriptModal";
import {mapGetters} from "vuex";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    Editor, OssMultiUpload,
    IndexExpoModal,
    ShopListModal,
    SheetListModal,
    UserListModal,
    ActivityListModal,
    IndexScriptModal
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      isShow: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      isShow6: false,
      isShow7: false,
      isShow8: false,
      isShow9: false,
      // 表单参数
      form: {
        id: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        title: null,

        introduction: null,

        content: null,
        jumpType: null,

        cover: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isDeleted: [
          {required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur'}
        ],

        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ],

        title: [
          {required: true, message: '标题不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.isShow = null
      this.isShow1 = null
      this.isShow2 = null
      this.isShow3 = null
      this.isShow4 = null
      this.isShow5 = null
      this.isShow6 = null
      this.isShow7 = null
      this.isShow8 = null
      this.isShow9 = null
      this.form = {
        id: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        title: null,

        introduction: null,

        content: null,
        jumpType: null,

        cover: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTuantuan(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    changeType(value) {
      console.log("123" + value);
      debugger
      this.isShow = false;
      this.isShow1 = false
      this.isShow2 = false
      this.isShow3 = false
      this.isShow4 = false
      this.isShow5 = false
      this.isShow6 = false
      this.isShow7 = false
      this.isShow8 = false
      this.isShow9 = false
      if (value == 0) {
        debugger
        this.isShow = true;
        this.form.remark = "绑定剧本"
      } else if (value == 1) {
        this.isShow1 = true;
        this.form.remark = ""
      } else if (value == 2) {
        this.isShow2 = true;
        this.form.remark = "请绑定店铺"
      } else if (value == 3) {
        this.isShow3 = true;
        this.form.remark = "请选择剧本集"
      } else if (value == 4) {
        this.isShow4 = true;
        this.form.remark = "请选择活动"
      } else if (value == 5) {
        this.isShow5 = true;
        this.form.remark = "绑定展会"
      } else if (value == 6) {
        this.isShow6 = true;
        this.form.remark = "请选择用户"
      } else if (value == 7) {
        this.isShow7 = true;
        this.form.remark = "请绑定商家"
      } else if (value == 8) {
        this.isShow8 = true;
        this.form.remark = ""
      } else if (value == 9) {
        this.isShow9 = true;
        this.form.remark = "请输入外链链接"
      }

    },
    handleInfoShop: function (id) {
      this.$refs.ShopListModal.open();
    },
    handleInfoActivity: function (id) {
      this.$refs.ActivityListModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    handleInfo: function (id) {
      this.$refs.IndexScriptModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    handleInfo5: function (id) {
      this.$refs.IndexExpoModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },

    handleInfo3: function (id) {
      this.$refs.SheetListModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    indexScriptModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    indexScriptModalName(scriptName) {
      this.$set(this.form, 'jumpInfoName', scriptName)
      this.$set(this.form, 'remark', scriptName)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的scriptName", scriptName, "123", this.form);
    },
    indexExpoModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    indexExpoModalName(expoName) {
      this.$set(this.form, 'jumpInfoName', expoName)
      this.$set(this.form, 'remark', expoName)
    },

    //剧本集
    sheetListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    //剧本集
    sheetListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的剧本集", name, "123", this.form);
    },

    //活动
    activityListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    //活动
    activityListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的活动", name, "123", this.form);
    },
    //店铺信息
    shopListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    shopListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的店铺", name, "123", this.form);
    },
    //用户信息
    handleInfoUser: function (id) {
      this.$refs.UserListModal.open();
    },
    userListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    userListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的用户", name, "123", this.form);
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTuantuan(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTuantuan(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
