<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="用户昵称" prop="nickName" >
        <a-input disabled v-model="form.nickName" placeholder="请输入用户昵称" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="电话" prop="moblie" >
        <a-input disabled v-model="form.mobile" placeholder="请输入用户电话" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="反馈类型" prop="type" >
        <a-select disabled placeholder="请选择反馈类型" v-model="form.type">
          <a-select-option value="1">意见建议</a-select-option>
          <a-select-option value="2">故障报告</a-select-option>
          <a-select-option value="3">投诉</a-select-option>
          <a-select-option value="4">其他</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="反馈图片" prop="img" >
        <!--        <a-input v-model="form.img" placeholder="请输入反馈图片地址" />-->
<!--        <img v-for="img in form.img" :src='img' style='height:150px;max-width:150px'/>-->
        <oss-multi-upload v-model="form.img" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="反馈内容" prop="content" >
        <editor disabled v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="备注" prop="remark" >
        <a-input disabled v-model="form.remark" placeholder="请输入备注" type="textarea"/>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="处理状态" prop="processStatus" >
        <a-select placeholder="请选择反馈类型" v-model="form.processStatus">
          <a-select-option value="1">待处理</a-select-option>
          <a-select-option value="2">已处理</a-select-option>
          <a-select-option value="3">暂不处理</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="处理结果" prop="processResult" >
        <a-input v-model="form.processResult" type="textarea"/>
      </a-form-model-item>

<!--      <a-form-model-item label="联系方式" prop="contact" >-->
<!--        <a-input v-model="form.contact" placeholder="请输入联系方式" />-->
<!--      </a-form-model-item>-->

      <div class="bottom-control" v-show="showButton">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAppFeedback, addAppFeedback, updateAppFeedback } from '@/api/feedback/appFeedback'
import Editor from '@/components/Editor'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssMultiUpload,
    Editor
  },
  data () {
    return {
      showButton: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,
        nickName: null,
        mobile:null,
        type: null,

        content: null,

        processStatus: 0,

        processResult: null,

        img: null,

        contact: null,

        remark: null,

        creator: null,

        createTime: null,

        modifier: null,

        updateTime: null,

        isDeleted: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，外键user_user.id不能为空', trigger: 'blur' }
        ],

        type: [
          { required: true, message: '反馈类型 1:意见建议,2,故障报告,3:投诉,4:其他不能为空', trigger: 'change' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '版本号不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      showButton: true,
      this.formType = 1
      this.form = {
        id: null,

        userId: null,
        nickName: null,
        mobile:null,
        type: null,

        content: null,

        processStatus: 0,

        processResult: null,

        img: null,

        contact: null,

        remark: null,

        creator: null,

        createTime: null,

        modifier: null,

        updateTime: null,

        isDeleted: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, type) {
      if (type == 1) {
        this.showButton = true
      }
      if (type == 2) {
        this.showButton = false
      }
      this.reset()
      this.formType = 2
      const id = row.id
      getAppFeedback(id).then(response => {
        this.form = response.data
        // if(response.data.img) {
        //   console.log(response.data.img)
        //   let img = response.data.img.split(",");
        //   console.log(img)
        //   this.form.img = img;
        //   console.log(this.form.img)
        // }
        this.open = true
        if (type == 1) {
          this.formTitle = '修改'
        }
        if (type == 2) {
          this.formTitle = '详情'
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAppFeedback(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAppFeedback(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
