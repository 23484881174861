<template>
  <a-modal v-model="visible" title="发放名片" width="50%" @close="onClose" :destroyOnClose="true">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称">
                <a-input v-model="queryParam.nickName" placeholder="请输入昵称" allow-clear />
              </a-form-item>

            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList(1)" />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered">
        <!-- 用户头像 -->
        <template slot="avatar" slot-scope="text, record">
          <div>
            <img v-if="record.avatar" :src="record.avatar" style="width:60px;height:62px;" @preview="handlePreview" />
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <a @click="grant(record)">
            发放
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from '@/components/DictCustomTag/index.vue'
import { listUser } from '@/api/user/user'

export default {
  name: 'UserGrantList',
  components: {
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      visible: false,
      list: [],
      ids: [],
      // 传值id
      relationId: null,
      selectedRowKeys: [],
      // 预览头像
      previewVisible: false,
      selectedRows: [],
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'UID',
          dataIndex: 'uid',
          ellipsis: true,
          align: 'center',
          width: 100
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询用户信息列表 */
    getList(type) {
      if (type === 1) {
        this.loading = true
        listUser(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
      }
      if (type === 3) {
        this.visible = false
      }
    },
    handleCancel() {
      this.previewVisible = false
    },
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList(1)
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        nickName: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList(1)
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList(1)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    open(relationId) {
      this.visible = true
      this.relationId = relationId
      // this.getList(1)
    },
    onClose() {
      this.visible = false
    },
    // 发放名片
    grant(row) {
      const that = this
      // let ids = []
      // if (row.id) {
      //   ids.push(row.id)
      // } else if (this.ids.length > 0) {
      //   ids = this.ids
      // }
      const relationId = this.relationId
      this.$emit('grantUser', row, relationId)
    }
  }
}
</script>
<style scoped>
>>> .table-page-search-wrapper .ant-form-inline .ant-form-item, >>> .table-page-search-submitButtons {
  margin-bottom: 0;
}
>>> .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
