import request from '@/utils/request'

// 查询剧本列表
export function listScript(query) {
  return request({
    url: '/script/script/list',
    method: 'get',
    params: query
  })
}

export function loadScriptOptionsByName(query) {
  return request({
    url: '/script/script/loadOptionsByName',
    method: 'get',
    params: query
  })
}

// 查询剧本详细
export function getScript(id) {
  return request({
    url: '/script/script/' + id,
    method: 'get'
  })
}

// 新增剧本
export function addScript(data) {
  return request({
    url: '/script/script',
    method: 'post',
    data: data
  })
}

// 修改剧本
export function updateScript(data) {
  return request({
    url: '/script/script',
    method: 'put',
    data: data
  })
}

// 删除剧本
export function delScript(id) {
  return request({
    url: '/script/script/' + id,
    method: 'delete'
  })
}

// 查询所有适龄岁数阶级枚举
export function appropriateAgeGroupMap() {
  return request({
    url: '/script/script/age/group/map',
    method: 'get'
  })
}

// 根据店铺查询所有剧本
export function listShopScript(query) {
  return request({
    url: '/script/script/shop/list',
    method: 'get',
    params: query
  })
}

// 店铺批量收录剧本
export function includeScript(data) {
  return request({
    url: '/script/script/shop/include',
    method: 'put',
    params: data
  })
}
