import request from '@/utils/request'

// 查询生成官方运营帐号纪录列表
export function listGmUserLog(query) {
  return request({
    url: '/gm/user/log/list',
    method: 'get',
    params: query
  })
}

// 查询生成官方运营帐号纪录详细
export function getGmUserLog(id) {
  return request({
    url: '/gm/user/log/' + id,
    method: 'get'
  })
}

// 新增生成官方运营帐号纪录
export function addGmUserLog(data) {
  return request({
    url: '/gm/user/log',
    method: 'post',
    data: data
  })
}

// 修改生成官方运营帐号纪录
export function updateGmUserLog(data) {
  return request({
    url: '/gm/user/log',
    method: 'put',
    data: data
  })
}

// 删除生成官方运营帐号纪录
export function delGmUserLog(id) {
  return request({
    url: '/gm/user/log/' + id,
    method: 'delete'
  })
}

// 批量增加运营号剧评
export function batchAddEvaluationList(data) {
  return request({
    url: '/gm/user/log/batch/add/evaluation',
    method: 'post',
    data: data
  })
}

// 指定运营号IM消息标记已读
export function markReadIMChatMsg(ids) {
  return request({
    url: '/gm/user/log/mark/read/im/msg/' + ids,
    method: 'post'
  })
}
