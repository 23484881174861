<template>
  <div>
    <div style="display: flex; justify-content: flex-end;">
      <a-button type="primary" style="margin-right: 10px" @click="addEvaluation">
        <a-icon type="plus" />
        新增剧评
      </a-button>
      <a-button type="danger" @click="delEvaluation">
        <a-icon type="delete" />
        删除当前剧评
      </a-button>
    </div>
    <a-list
      item-layout="vertical"
      size="large"
      :pagination="{
        current: currentPage,
        pageSize: pageSize,
        total: evaluationList.length,
        onChange: onChange
      }"
      :data-source="evaluationList"
    >
      <a-list-item
        v-for="elItem in paginatedItems"
        :key="elItem.userId"
      >
        <a-form-model :ref="`form${eIndex}`" :model="elItem" :rules="rules" v-if="elItem">
          <a-row :gutter="32">
            <a-col :span="8">
              <a-form-model-item label="剧本名称" prop="scriptId" >
                <a-button type="dashed" @click="showBindScript" danger v-if="elItem && elItem.scriptName">
                  <span>{{ elItem.scriptName }}</span>
                </a-button>
                <a-button type="dashed" @click="showBindScript" danger v-if="elItem && !elItem.scriptName">
                  <span style="color: dodgerblue"> 绑定剧本</span>
                </a-button>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="维度1选择" prop="scriptScoreBO.dimension1Name">
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.dimension1Name">
                  <a-select-option v-for="(item, index) in reviewDimension" :value="item" :key="index">
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="维度1评分" prop="scriptScoreBO.dimension1Score">
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.dimension1Score" placeholder="请选择评分">
                  <a-select-option v-for="i in 10" :value="i" :key="i">
                    {{ i }}分
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="评分等级" prop="scriptScoreBO.level">
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.level" placeholder="请选择评分等级">
                  <a-select-option v-for="(item, index) in customDict.LeaveTypeEnum" :value="item.type" :key="index" v-if="item.type !== 4">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="维度2选择" prop="scriptScoreBO.dimension2Name">
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.dimension2Name">
                  <a-select-option v-for="(item, index) in reviewDimension" :value="item" :key="index">
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="维度2评分" prop="scriptScoreBO.dimension2Score">
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.dimension2Score" placeholder="请选择评分">
                  <a-select-option v-for="i in 10" :value="i" :key="i">
                    {{ i }}分
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="体验角色" prop="scriptRole">
                <a-select
                  v-model="elItem.scriptRole"
                  mode="tags"
                  allow-clear
                  class="evaluateScore"
                  :token-separators="[',']"
                  placeholder="请选择体验角色"
                  @change="handleChange"
                >
                  <a-select-option
                    v-for="(item, index) in elItem.scriptRoleVOS"
                    :key="index"
                    :value="item.roleName"
                  >
                    {{ item.roleName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="维度3选择" prop="scriptScoreBO.dimension3Name">
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.dimension3Name">
                  <a-select-option v-for="(item, index) in reviewDimension" :value="item" :key="index">
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                label="维度3评分"
                prop="scriptScoreBO.dimension3Score"
                :rules="[{ required: true, message: '请选择评分!', trigger: 'change' }]"
              >
                <a-select class="evaluateScore" v-model="elItem.scriptScoreBO.dimension3Score" placeholder="请选择评分">
                  <a-select-option v-for="i in 10" :value="i" :key="i">
                    {{ i }}分
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="22">
              <a-form-model-item label="内容" prop="content" >
                <a-input
                  v-model="elItem.content"
                  placeholder="请输入内容"
                  type="textarea"
                  :autoSize="{ minRows: 4, maxRows: 6 }"
                  allow-clear
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="32">
            <a-col span="16">
              <a-form-model-item label="图片" prop="图片" v-if="!elItem.video">
                <oss-multi-upload v-model="elItem.img" type="img" :maxSize=10 :maxCount=9></oss-multi-upload>
              </a-form-model-item>
            </a-col>
            <a-col span="4">
              <a-form-model-item label="视频" prop="video" v-if="!elItem.img">
                <oss-multi-upload v-model="elItem.video" type="video" :maxSize=10 :maxCount=1></oss-multi-upload>
              </a-form-model-item>
            </a-col>
            <a-col span="2">
              <a-form-model-item label="是否剧透" prop="isSpoiler">
                <a-switch v-model="elItem.isSpoiler" />{{ elItem.isSpoiler ? '是' : '否' }}
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-list-item>
    </a-list>
    <index-script-modal
      ref="IndexScriptModal"
      @select="indexScriptModalSelect"
      @selectAll="indexScriptModalAll"
    ></index-script-modal>
  </div>
</template>

<script>
import Editor from '@/components/Editor'
import { mapGetters } from 'vuex'
import { getScriptReviewDimension } from '@/api/community/article'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
import CustomDictTag from '@/components/DictCustomTag'
import IndexScriptModal from '@/components/Script/IndexScriptModal.vue'

export default {
  name: 'BatchDramaReviewsAdd',
  props: {
    evaluationList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    IndexScriptModal,
    Editor,
    OssMultiUpload,
    CustomDictTag
  },
  data() {
    const dimension1NameVal = (rule, value, callback) => {
      const that = this
      if (value === that.evaluationList[that.eIndex].scriptScoreBO.dimension2Name ||
        value === that.evaluationList[that.eIndex].scriptScoreBO.dimension3Name
      ) {
        callback(new Error('不可与其他维度重复！'))
      } else {
        callback()
      }
    }
    const dimension2NameVal = (rule, value, callback) => {
      const that = this
      if (value === that.evaluationList[that.eIndex].scriptScoreBO.dimension1Name ||
        value === that.evaluationList[that.eIndex].scriptScoreBO.dimension3Name
      ) {
        callback(new Error('不可与其他维度重复！'))
      } else {
        callback()
      }
    }
    const dimension3NameVal = (rule, value, callback) => {
      const that = this
      if (value === that.evaluationList[that.eIndex].scriptScoreBO.dimension1Name ||
        value === that.evaluationList[that.eIndex].scriptScoreBO.dimension2Name
      ) {
        callback(new Error('不可与其他维度重复！'))
      } else {
        callback()
      }
    }
    return {
      currentPage: 1,
      pageSize: 1,
      submitLoading: false,
      eIndex: 0,
      form: {},
      reviewDimension: [],
      rules: {
        // type: [
        //   { required: true, message: '类型 1:动态,2:剧评,3:展评,4:店评不能为空', trigger: 'change' }
        // ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { min: 20, max: 8000, message: '内容字数在20-8000之间', trigger: 'blur' }
        ],
        // userId: [
        //   { required: true, message: '用户id 外键user_user.id不能为空', trigger: 'blur' }
        // ],
        shopId: [
          { required: true, message: '店铺id不能为空', trigger: 'blur' }
        ],
        scriptId: [
          { required: true, message: '剧本id 外键script_script.id不能为空', trigger: 'change' }
        ],
        // scriptRole: [
        //   { required: true, message: '扮演角色不能为空', trigger: 'blur' }
        // ],
        'scriptScoreBO.level': [{ required: true, message: '请选择评分等级!', trigger: 'change' }],
        'scriptScoreBO.dimension1Name': [{ required: true, validator: dimension1NameVal, trigger: 'change' }],
        'scriptScoreBO.dimension2Name': [{ required: true, validator: dimension2NameVal, trigger: 'change' }],
        'scriptScoreBO.dimension3Name': [{ required: true, validator: dimension3NameVal, trigger: 'change' }],
        'scriptScoreBO.dimension1Score': [{ required: true, message: '请选择评分!', trigger: 'change' }],
        'scriptScoreBO.dimension2Score': [{ required: true, message: '请选择评分!', trigger: 'change' }],
        'scriptScoreBO.dimension3Score': [{ required: true, message: '请选择评分!', trigger: 'change' }]
      },
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict']),
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.evaluationList.slice(start, start + this.pageSize);
    },
  },
  watch: {},
  mounted() {
    getScriptReviewDimension().then(res => {
      this.reviewDimension = res.data
    }).catch(err => {
      this.$message.error('获取剧评多维度失败' + err)
    })
  },
  methods: {
    onChange (page) {
      this.eIndex = page - 1
      this.currentPage = page; // 更新当前页
      console.log(page)
    },
    indexScriptModalSelect(ids) {
      this.evaluationList[this.eIndex].scriptId = ids
    },
    indexScriptModalAll(script) {
      this.evaluationList[this.eIndex].scriptRole = undefined
      this.evaluationList[this.eIndex].scriptName = script.name
      this.evaluationList[this.eIndex].scriptRoleVOS = script.scriptScriptRoleVOS
    },
    handleChange(value) {
      // 只保留最后一个，清空其他选择
      if (value.length > 0) {
        this.evaluationList[this.eIndex].scriptRole = value[value.length - 1]
      } else {
        this.evaluationList[this.eIndex].scriptRole = undefined
      }
    },
    addEvaluation () {
      this.evaluationList.push({
        type: 2,
        userId: null,
        scriptId: null,
        scriptName: null,
        content: null,
        img: null,
        video: null,
        oneImgWidth: null,
        oneImgHeight: null,
        isSpoiler: false,
        scriptRole: undefined,
        scriptRoleVOS: [],
        scriptScoreBO: {
          level: undefined,
          dimension1Name: this.reviewDimension[0],
          dimension1Score: undefined,
          dimension2Name: this.reviewDimension[1],
          dimension2Score: undefined,
          dimension3Name: this.reviewDimension[2],
          dimension3Score: undefined
        },
        shopExperience: null,
        shopId: null,
      })
      this.currentPage = this.evaluationList.length
      this.eIndex = this.currentPage - 1
    },
    delEvaluation () {
      this.evaluationList.splice(this.eIndex, 1)
      if (this.evaluationList.length > 0) {
        this.currentPage = 1
        this.eIndex = 0
      }
    },
    checkAllVal () {
      let valArray = []
      const that = this
      // 遍历所有表单
      this.evaluationList.forEach((_, index) => {
        const formRef = that.$refs['form' + index][0];
        if (formRef) {
          valArray.push(formRef.validate())
        }
      });

      return Promise.all(valArray)
    },
    showBindScript: function() {
      this.$refs.IndexScriptModal.open()
    }
  }
}
</script>
<style lang="css" scoped>
>>> .ant-modal-body {
  padding-top: 10px;
}
.evaluateScore {
  width: 230px;
}
.ant-form .ant-form-item {
  //margin-bottom: 0;
}
.ant-form >>> .ant-form-item-label {
  height: 20px;
  line-height: 20px;
}
.ant-list-pagination {
  margin-top: 0;
}
</style>
