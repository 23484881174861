<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="运营号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="运营号昵称" prop="name">
                <a-input v-model="queryParam.nickName" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" :disabled="multiple" @click="markRead">
          <a-icon type="eye-invisible" />批量已读
        </a-button>
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['config:tabs:add']">-->
        <!--          <a-icon type="plus" />新增-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['config:tabs:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['config:tabs:remove']">-->
        <!--          <a-icon type="delete" />删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['config:tabs:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <div slot="avatar" slot-scope="text, record">
          <img v-if="record.avatar" :src="record.avatar" style="width:60px;height:60px;cursor:pointer;" preview alt=""/>
        </div>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="markRead(record)" v-if="record.unreadMsgCounts && record.unreadMsgCounts > 0">
            <a-icon type="eye-invisible"/>标记已读
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['config:tabs:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['config:tabs:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['config:tabs:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['config:tabs:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
        <template slot="img" slot-scope="text, record">
          <div>
            <img v-if="record.img" :src="record.img"  style="width:60px;height:62px;" />
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        :pageSizeOptions="['10', '20', '50', '100']"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getGmUserIMUnreadList } from '@/api/gm/gmUser'
import { markReadIMChatMsg } from '@/api/gm/gmUserLog'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Tabs',
  components: {
    // CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickName: null,
        mobil: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '运营号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
          width: 130
        },
        {
          title: '运营号头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          width: 150,
          scopedSlots: { customRender: 'avatar' },
        },
        {
          title: '运营号昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'left',
          width: 200
        },
        {
          title: '地址',
          ellipsis: true,
          align: 'left',
          width: 250,
          customRender: (text, row) => {
            return row.areap + row.areac + row.areax
          }
        },
        {
          title: '未读消息数',
          dataIndex: 'unreadMsgCounts',
          ellipsis: true,
          align: 'center',
          width: 150
        },
        {
          title: '更新消息时间',
          dataIndex: 'updateTime',
          ellipsis: true,
          align: 'left',
          width: 190
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 180,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询列表 */
    getList () {
      this.loading = true
      getGmUserIMUnreadList(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        nickName: null,
        mobil: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    markRead (row) {
      const ids = row.id || this.ids
      markReadIMChatMsg(ids).then(res => {
        if (res.success) {
          this.getList()
          this.$message.success("标记成功")
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delTabs(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('config/tabs/export', {
            ...that.queryParam
          }, `tabs_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
