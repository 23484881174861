import request from '@/utils/request'

// 查询徽章列表
export function listBadge(query) {
  return request({
    url: '/badge/badge/list',
    method: 'get',
    params: query
  })
}

// 查询徽章详细
export function getBadge(id) {
  return request({
    url: '/badge/badge/' + id,
    method: 'get'
  })
}

// 新增徽章
export function addBadge(data) {
  return request({
    url: '/badge/badge',
    method: 'post',
    data: data
  })
}

// 修改徽章
export function updateBadge(data) {
  return request({
    url: '/badge/badge',
    method: 'put',
    data: data
  })
}

// 删除徽章
export function delBadge(id) {
  return request({
    url: '/badge/badge/' + id,
    method: 'delete'
  })
}
