<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="法人名称" prop="legalPerson">
                <a-input v-model="queryParam.legalPerson" placeholder="请输入法人名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="联系电话" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入联系电话" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="审核状态" prop="status">
                <a-select placeholder="请选择审核状态" style="width: 100%" v-model="queryParam.status" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ShopAuditStatusEnum" :value="item.type" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:shop/audit:add']">-->
        <!--          <a-icon type="plus" />新增-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:shop/audit:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:shop/audit:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:shop/audit:export']">
                  <a-icon type="download" />导出
                </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider v-if="record.status === 2" type="vertical" v-hasPermi="['shop:shop/audit:verify']"/>-->
<!--          <a v-if="record.status === 2" @click="handleAudit(record, undefined)" v-hasPermi="['shop:shop/audit:verify']">-->
<!--            <a-icon type="audit"/>审批-->
<!--          </a>-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['config:appellation/condition:edit']">
            <a-icon type="edit"/>{{ record.status === 2 ? '审批' : '详情' }}
          </a>
          <!--          <a-divider type="vertical" v-hasPermi="['shop:shop/audit:remove']" />-->
          <!--          <a @click="handleDelete(record)" v-hasPermi="['shop:shop/audit:remove']">-->
          <!--            <a-icon type="delete" />删除-->
          <!--          </a>-->
        </span>
        <template slot="logo" slot-scope="text, record">
          <img v-if="record.logo" :src="record.logo" style="width:60px;height:62px;" @preview="handlePreview"/>
        </template>
        <template slot="inDoorImg" slot-scope="text, record">
          <img v-if="record.inDoorImg" :src="record.inDoorImg" style="width:60px;height:62px;"
               @preview="handlePreview"/>
        </template>
        <template slot="outDoorImg" slot-scope="text, record">
          <img v-if="record.outDoorImg" :src="record.outDoorImg" style="width:60px;height:62px;"
               @preview="handlePreview"/>
        </template>
        <span slot="status" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.ShopAuditStatusEnum" :value="record.status"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
<!--    <div>-->
<!--      <a-modal v-model="visible" title="店铺审批" @ok="handleOk">-->
<!--        <a-select-->
<!--          ref="select"-->
<!--          v-model="shopStatus"-->
<!--          style="width: 120px"-->
<!--        >-->
<!--          <a-select-option value="3">通过</a-select-option>-->
<!--          <a-select-option value="4">驳回</a-select-option>-->
<!--        </a-select>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <a-textarea v-if="shopStatus === '4'" v-model="reason" placeholder="驳回原因" :maxlength="200" allow-clear/>-->
<!--      </a-modal>-->
<!--    </div>-->
  </page-header-wrapper>
</template>

<script>
import {listShopAudit, delShopAudit, shopAuditVerify} from '@/api/shop/shopAudit'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {parseTime} from '../../../utils/ruoyi'
import {timeFix} from '@/utils/util'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'ShopAudit',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedId: '',
      shopStatus: '3',
      reason: '',
      visible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        legalPerson: null,
        mobile: null,
        inDoorImg: null,
        outDoorImg: null,
        logo: null,
        shopName: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        address: null,
        servicePhone: null,
        talkInfo: null,
        label: null,
        img: null,
        video: null,
        priceBoxed: null,
        priceCityLimit: null,
        priceSole: null,
        priceRealScene: null,
        priceMicro: null,
        reason: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '法人名称',
          dataIndex: 'legalPerson',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '联系电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺logo',
          dataIndex: 'logo',
          scopedSlots: {customRender: 'logo'},
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '店铺内景',
        //   dataIndex: 'inDoorImg',
        //   scopedSlots: { customRender: 'inDoorImg' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店铺外景',
        //   dataIndex: 'outDoorImg',
        //   scopedSlots: { customRender: 'outDoorImg' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: {customRender: 'createTime'},
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          ellipsis: true,
          customRender: (text, record) => {
            return <span>{record.submitTime == null ? record.createTime : record.submitTime}</span>
          },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    parseTime,
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 查询店铺审核信息列表 */
    getList() {
      this.loading = true
      listShopAudit(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        legalPerson: undefined,
        isEnable: undefined,
        mobile: undefined,
        inDoorImg: undefined,
        outDoorImg: undefined,
        logo: undefined,
        shopName: undefined,
        areaCode: undefined,
        longitude: undefined,
        latitude: undefined,
        address: undefined,
        servicePhone: undefined,
        talkInfo: undefined,
        label: undefined,
        img: undefined,
        video: undefined,
        priceBoxed: undefined,
        priceCityLimit: undefined,
        priceSole: undefined,
        priceRealScene: undefined,
        priceMicro: undefined,
        reason: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delShopAudit(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('shop/shop/audit/export', {
            ...that.queryParam
          }, `shop/audit_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    handleOk() {
      shopAuditVerify({id: this.selectedId, status: this.shopStatus, reason: this.reason}).then(response => {
        if (response.success) {
          this.$notification.success({
            message: '审批成功'
          })
        }
      }).finally(() => {
        this.shopStatus = '3'
        this.visible = false
        this.selectedId = ''
        this.reason = ''
        this.handleQuery()
      })
    },
    /** 审批 */
    handleAudit(row, ids) {
      this.formType = 2
      const id = row ? row.id : ids
      this.visible = true
      this.selectedId = id
    }
  }
}
</script>
